import React, { useRef } from "react"
import { useGLTF } from "@react-three/drei"
import { useDispatch } from "react-redux"

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF(
    "/assets/LOE_Environment_THREE_MODEL_COMBINED_SCALED.gltf"
  )

  const sendActionToStore = useDispatch()

  function updateLastPositionClicked(newX, newY) {
    // console.log(newX, newY)
    sendActionToStore({
      type: "projectSceneDesigner/updatePosition",
      payload: {
        x: newX,
        y: newY,
      },
    })
  }
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        receiveShadow
        geometry={nodes.Straßen.geometry}
        material={nodes.Straßen.material}
        position={[-4.52, 0.01, 21.06]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube.geometry}
        material={nodes.Cube.material}
        position={[3.07, 0.42, -38.18]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube022.geometry}
        material={nodes.Cube022.material}
        position={[11.24, 0.5, -40.71]}
        rotation={[Math.PI, 0, 2.79]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube054.geometry}
        material={nodes.Cube054.material}
        position={[11.24, 0.5, -42.07]}
        rotation={[Math.PI, 0, 2.79]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube080.geometry}
        material={nodes.Cube080.material}
        position={[8.61, 0.31, -35.49]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder043.geometry}
        material={nodes.Cylinder043.material}
        position={[7.76, 1.12, -36.85]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube093.geometry}
        material={nodes.Cube093.material}
        position={[9.46, 0.16, -15.87]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube094.geometry}
        material={nodes.Cube094.material}
        position={[8.22, 0.16, -25.87]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube095.geometry}
        material={nodes.Cube095.material}
        position={[7, 0.18, -26.02]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube096.geometry}
        material={nodes.Cube096.material}
        position={[16.11, 0.16, -23.35]}
        rotation={[-Math.PI, -0.65, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube097.geometry}
        material={nodes.Cube097.material}
        position={[17.16, 0.18, -23.97]}
        rotation={[-Math.PI, -0.65, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube084.geometry}
        material={nodes.Cube084.material}
        position={[10.75, 0.16, -9.21]}
        rotation={[0, -1.02, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube091.geometry}
        material={nodes.Cube091.material}
        position={[10.23, 0.18, -10.33]}
        rotation={[0, -1.02, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube092.geometry}
        material={nodes.Cube092.material}
        position={[11.09, 0.16, -5.42]}
        rotation={[0, -0.09, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube111.geometry}
        material={nodes.Cube111.material}
        position={[9.89, 0.18, -6.78]}
        rotation={[0, -0.09, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane001.geometry}
        material={nodes.Plane001.material}
        position={[-3.16, -0.01, -9.83]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Water.geometry}
        material={materials.Water}
        position={[0, -0.15, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder022.geometry}
        material={nodes.Cylinder022.material}
        position={[7.84, 0.09, -2.1]}
        rotation={[0, 1.54, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder023.geometry}
        material={nodes.Cylinder023.material}
        position={[-31.1, 0.07, 25.78]}
        rotation={[0, -0.59, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder024.geometry}
        material={nodes.Cylinder024.material}
        position={[-17.51, 0.07, 41.28]}
        rotation={[0, -0.59, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder025.geometry}
        material={nodes.Cylinder025.material}
        position={[-19.23, 0.07, 43.26]}
        rotation={[Math.PI, -1.2, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder026.geometry}
        material={nodes.Cylinder026.material}
        position={[5.69, 0.07, -31.35]}
        rotation={[0, -0.59, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder.geometry}
        material={nodes.Cylinder.material}
        position={[4.5, 0.11, -0.69]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder001.geometry}
        material={nodes.Cylinder001.material}
        position={[5.64, 0.09, -2.94]}
        rotation={[0, 0.72, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder002.geometry}
        material={nodes.Cylinder002.material}
        position={[5.87, 0.1, -15.6]}
        rotation={[0, 0.72, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder003.geometry}
        material={nodes.Cylinder003.material}
        position={[-26.11, 0.11, -20.6]}
        rotation={[0, -0.99, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder004.geometry}
        material={nodes.Cylinder004.material}
        position={[-27.76, 0.07, -19.38]}
        rotation={[0, -0.38, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder005.geometry}
        material={nodes.Cylinder005.material}
        position={[-27.39, 0.11, -21.19]}
        rotation={[0, 0.28, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder006.geometry}
        material={nodes.Cylinder006.material}
        position={[-29.49, -0.06, -20.15]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder007.geometry}
        material={nodes.Cylinder007.material}
        position={[-20.07, 0.11, -31.87]}
        rotation={[0, -0.21, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder011.geometry}
        material={nodes.Cylinder011.material}
        position={[1.13, 0.07, 22.33]}
        rotation={[0, -0.59, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder010.geometry}
        material={nodes.Cylinder010.material}
        position={[2.2, 0.07, 26.21]}
        rotation={[0, 0.03, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder012.geometry}
        material={nodes.Cylinder012.material}
        position={[11.17, 0.07, 17.73]}
        rotation={[0, 1.57, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder013.geometry}
        material={nodes.Cylinder013.material}
        position={[-15.51, 0.06, -59.03]}
        rotation={[Math.PI, -0.51, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder014.geometry}
        material={nodes.Cylinder014.material}
        position={[-1.69, 0.1, -52.09]}
        rotation={[0, -1.36, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder015.geometry}
        material={nodes.Cylinder015.material}
        position={[-17.79, -0.02, -57.94]}
        rotation={[0, -0.59, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder016.geometry}
        material={nodes.Cylinder016.material}
        position={[-16.49, 0.05, -57.21]}
        rotation={[0, -1.2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder017.geometry}
        material={nodes.Cylinder017.material}
        position={[-36.92, 0.07, 16.76]}
        rotation={[0, -0.59, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder018.geometry}
        material={nodes.Cylinder018.material}
        position={[-1.95, 0.28, 5.09]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder019.geometry}
        material={nodes.Cylinder019.material}
        position={[16.98, 0.07, -39.5]}
        rotation={[0, 0.17, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder020.geometry}
        material={nodes.Cylinder020.material}
        position={[40.06, 0.07, 14.57]}
        rotation={[0, 0.17, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder028.geometry}
        material={nodes.Cylinder028.material}
        position={[3.21, 0.1, -11.86]}
        rotation={[0, 0.89, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder029.geometry}
        material={nodes.Cylinder029.material}
        position={[2.07, 0.1, -10.2]}
        rotation={[0, 1.21, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder030.geometry}
        material={nodes.Cylinder030.material}
        position={[0.02, 0.1, -11.83]}
        rotation={[0, 0.23, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.WaterSides.geometry}
        material={nodes.WaterSides.material}
        position={[0, -0.17, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder021.geometry}
        material={nodes.Cylinder021.material}
        position={[-30.5, -0.04, -18.13]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder027.geometry}
        material={nodes.Cylinder027.material}
        position={[-32.78, -0.12, -17.37]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder031.geometry}
        material={nodes.Cylinder031.material}
        position={[-32.78, 0, -21.78]}
        rotation={[0, -0.34, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder033.geometry}
        material={nodes.Cylinder033.material}
        position={[-19.23, -0.04, -9.71]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder034.geometry}
        material={nodes.Cylinder034.material}
        position={[-19.23, -0.04, -8.38]}
        rotation={[0, -1.14, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder035.geometry}
        material={nodes.Cylinder035.material}
        position={[-19.23, -0.04, -7.04]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder036.geometry}
        material={nodes.Cylinder036.material}
        position={[-20.86, -0.04, -4.66]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder037.geometry}
        material={nodes.Cylinder037.material}
        position={[-19.22, -0.04, -3.21]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder038.geometry}
        material={nodes.Cylinder038.material}
        position={[-20.86, -0.04, -1.88]}
        rotation={[0, -1.14, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder039.geometry}
        material={nodes.Cylinder039.material}
        position={[-17.37, -0.04, -6.85]}
        rotation={[0, 1.56, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder040.geometry}
        material={nodes.Cylinder040.material}
        position={[-17.61, 0.07, -8.38]}
        rotation={[-Math.PI, 1.38, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder041.geometry}
        material={nodes.Cylinder041.material}
        position={[-17.37, -0.04, -9.71]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder042.geometry}
        material={nodes.Cylinder042.material}
        position={[17.51, 0.11, -2.33]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder044.geometry}
        material={nodes.Cylinder044.material}
        position={[6.34, 0.07, -0.23]}
        rotation={[0, 0.17, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder045.geometry}
        material={nodes.Cylinder045.material}
        position={[7.84, 0.09, -0.63]}
        rotation={[0, 1.19, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder046.geometry}
        material={nodes.Cylinder046.material}
        position={[2.86, 0.02, -2.37]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder047.geometry}
        material={nodes.Cylinder047.material}
        position={[-12.42, -0.04, 20.28]}
        rotation={[0, -1.14, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder048.geometry}
        material={nodes.Cylinder048.material}
        position={[-14.12, -0.04, 22.76]}
        rotation={[Math.PI, -1.29, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder049.geometry}
        material={nodes.Cylinder049.material}
        position={[1.61, 0.06, 15.1]}
        rotation={[0, -1.07, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder050.geometry}
        material={nodes.Cylinder050.material}
        position={[2.66, -0.02, 17.4]}
        rotation={[0, 0.97, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder051.geometry}
        material={nodes.Cylinder051.material}
        position={[1.18, 0.05, 19.03]}
        rotation={[0, 0.35, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder052.geometry}
        material={nodes.Cylinder052.material}
        position={[0.86, 0.06, 16.07]}
        rotation={[0, 1.18, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder053.geometry}
        material={nodes.Cylinder053.material}
        position={[1.96, -0.02, 13.8]}
        rotation={[-Math.PI, 1.45, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder054.geometry}
        material={nodes.Cylinder054.material}
        position={[0.49, 0.05, 14.03]}
        rotation={[-Math.PI, 0.53, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder055.geometry}
        material={nodes.Cylinder055.material}
        position={[0.26, 0.06, 11.74]}
        rotation={[0, -1.07, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder056.geometry}
        material={nodes.Cylinder056.material}
        position={[-2.76, 0.07, -21.74]}
        rotation={[-Math.PI, 0.41, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder057.geometry}
        material={nodes.Cylinder057.material}
        position={[-3.73, -0.04, -22.68]}
        rotation={[-Math.PI, -0.97, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder058.geometry}
        material={nodes.Cylinder058.material}
        position={[-2.85, 0.07, -15.08]}
        rotation={[-Math.PI, -1.03, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder059.geometry}
        material={nodes.Cylinder059.material}
        position={[-3.91, -0.04, -14.24]}
        rotation={[0, -0.73, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder060.geometry}
        material={nodes.Cylinder060.material}
        position={[-15.19, -0.04, 8.47]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder061.geometry}
        material={nodes.Cylinder061.material}
        position={[-14.06, -0.04, 9.62]}
        rotation={[0, 1.56, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder062.geometry}
        material={nodes.Cylinder062.material}
        position={[-14.06, -0.04, 6.29]}
        rotation={[0, 1.56, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder063.geometry}
        material={nodes.Cylinder063.material}
        position={[-16.11, -0.04, 6.39]}
        rotation={[0, 0.74, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder064.geometry}
        material={nodes.Cylinder064.material}
        position={[-17.37, -0.04, -2.83]}
        rotation={[0, 1.56, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder065.geometry}
        material={nodes.Cylinder065.material}
        position={[-17.61, 0.07, -4.36]}
        rotation={[-Math.PI, 1.38, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder066.geometry}
        material={nodes.Cylinder066.material}
        position={[-12.13, -0.04, -4.36]}
        rotation={[-Math.PI, -1.56, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder067.geometry}
        material={nodes.Cylinder067.material}
        position={[-11.9, 0.07, -2.83]}
        rotation={[0, -1.38, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder068.geometry}
        material={nodes.Cylinder068.material}
        position={[-16.18, -0.04, -4.07]}
        rotation={[0, -0.83, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder069.geometry}
        material={nodes.Cylinder069.material}
        position={[-14.96, 0.07, -3.12]}
        rotation={[0, -0.63, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder070.geometry}
        material={nodes.Cylinder070.material}
        position={[-13.17, -0.04, -3.26]}
        rotation={[-Math.PI, 0.83, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder071.geometry}
        material={nodes.Cylinder071.material}
        position={[-14.39, 0.07, -4.21]}
        rotation={[-Math.PI, 0.63, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder072.geometry}
        material={nodes.Cylinder072.material}
        position={[7.8, 0.07, 13.4]}
        rotation={[0, 1.57, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder073.geometry}
        material={nodes.Cylinder073.material}
        position={[-6.13, 0.06, 16.51]}
        rotation={[0, -1.07, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder074.geometry}
        material={nodes.Cylinder074.material}
        position={[-5.78, -0.02, 15.2]}
        rotation={[-Math.PI, 1.45, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder075.geometry}
        material={nodes.Cylinder075.material}
        position={[-7.25, 0.05, 15.44]}
        rotation={[-Math.PI, 0.53, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder076.geometry}
        material={nodes.Cylinder076.material}
        position={[-13.21, -0.04, 14.79]}
        rotation={[-Math.PI, 1.14, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder077.geometry}
        material={nodes.Cylinder077.material}
        position={[-3.19, -0.02, 19.26]}
        rotation={[-Math.PI, 1.45, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder078.geometry}
        material={nodes.Cylinder078.material}
        position={[-7.61, -0.02, 20.41]}
        rotation={[-Math.PI, 1.45, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder079.geometry}
        material={nodes.Cylinder079.material}
        position={[-32.31, 0.06, -44.31]}
        rotation={[-Math.PI, 1.04, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder080.geometry}
        material={nodes.Cylinder080.material}
        position={[-33.45, -0.02, -46.57]}
        rotation={[Math.PI, -1, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder081.geometry}
        material={nodes.Cylinder081.material}
        position={[-34.16, 0.05, -45.26]}
        rotation={[Math.PI, -0.39, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder082.geometry}
        material={nodes.Cylinder082.material}
        position={[9.06, 0.09, -1.19]}
        rotation={[-Math.PI, -0.73, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder083.geometry}
        material={nodes.Cylinder083.material}
        position={[10.12, 0.09, -2.21]}
        rotation={[-Math.PI, -0.38, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder084.geometry}
        material={nodes.Cylinder084.material}
        position={[8.43, 0.09, 0.34]}
        rotation={[-Math.PI, -0.49, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder085.geometry}
        material={nodes.Cylinder085.material}
        position={[10.05, 0.09, -1.15]}
        rotation={[-Math.PI, 0.05, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder087.geometry}
        material={nodes.Cylinder087.material}
        position={[-21.24, 0.07, 22.74]}
        rotation={[0, -0.59, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder088.geometry}
        material={nodes.Cylinder088.material}
        position={[-19.25, 0.07, 10.56]}
        rotation={[0, -1.1, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder090.geometry}
        material={nodes.Cylinder090.material}
        position={[8.62, 0.06, 5.48]}
        rotation={[0, -1.07, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder091.geometry}
        material={nodes.Cylinder091.material}
        position={[-6.32, -0.04, 39.33]}
        rotation={[Math.PI, -1.29, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder092.geometry}
        material={nodes.Cylinder092.material}
        position={[-3, -0.02, 31.21]}
        rotation={[-Math.PI, 1.45, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder093.geometry}
        material={nodes.Cylinder093.material}
        position={[17.3, 0.07, 35.6]}
        rotation={[0, -0.33, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder094.geometry}
        material={nodes.Cylinder094.material}
        position={[-6.48, -0.04, 34.93]}
        rotation={[Math.PI, -1.29, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder095.geometry}
        material={nodes.Cylinder095.material}
        position={[-1.11, -0.02, 34.62]}
        rotation={[-Math.PI, -0.97, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder096.geometry}
        material={nodes.Cylinder096.material}
        position={[-0.09, 0.05, 37.12]}
        rotation={[-Math.PI, -0.35, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Bridge.geometry}
        material={nodes.Bridge.material}
        position={[-19.93, 0.17, 3.13]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder008.geometry}
        material={nodes.Cylinder008.material}
        position={[-25.18, 1.54, -41.58]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder009.geometry}
        material={nodes.Cylinder009.material}
        position={[7.8, 0.07, 15.83]}
        rotation={[-Math.PI, 1.52, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube053.geometry}
        material={nodes.Cube053.material}
        position={[0.11, -0.02, 3.04]}
        rotation={[0, 0.01, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder032.geometry}
        material={nodes.Cylinder032.material}
        position={[-13.68, 0.07, -8.31]}
        rotation={[-Math.PI, 1.52, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Bridge001.geometry}
        material={nodes.Bridge001.material}
        position={[-10.46, 0.17, 3.13]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder086.geometry}
        material={nodes.Cylinder086.material}
        position={[11.23, 0.03, 1.02]}
        rotation={[0, -1.17, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder089.geometry}
        material={nodes.Cylinder089.material}
        position={[-25.18, 0.46, -26.2]}
        rotation={[0, -0.4, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube008.geometry}
        material={nodes.Cube008.material}
        position={[-29.91, 0.88, -4.12]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube009.geometry}
        material={nodes.Cube009.material}
        position={[-29.9, 0.88, -7.18]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube010.geometry}
        material={nodes.Cube010.material}
        position={[-27.68, 0.89, -4.11]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube011.geometry}
        material={nodes.Cube011.material}
        position={[-7.31, 1, -4.22]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube012.geometry}
        material={nodes.Cube012.material}
        position={[-9.16, 0.92, -4.49]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube013.geometry}
        material={nodes.Cube013.material}
        position={[-7.3, 1, -7.38]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube014.geometry}
        material={nodes.Cube014.material}
        position={[-14.95, 0.88, -28.75]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube015.geometry}
        material={nodes.Cube015.material}
        position={[-16.8, 0.88, -28.76]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube016.geometry}
        material={nodes.Cube016.material}
        position={[-14.94, 0.88, -30.88]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube017.geometry}
        material={nodes.Cube017.material}
        position={[2.89, 1, -19.23]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube018.geometry}
        material={nodes.Cube018.material}
        position={[2.9, 1, -21.08]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube019.geometry}
        material={nodes.Cube019.material}
        position={[5.12, 1, -19.22]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle.geometry}
        material={nodes.Circle.material}
        position={[-21.64, -0.02, -15.87]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube020.geometry}
        material={nodes.Cube020.material}
        position={[3.69, 0.95, -14.41]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube023.geometry}
        material={nodes.Cube023.material}
        position={[-10.23, 0.59, -38.86]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube024.geometry}
        material={nodes.Cube024.material}
        position={[-14.93, 0.95, -41.75]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube025.geometry}
        material={nodes.Cube025.material}
        position={[-23.36, 0.02, -39.2]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube026.geometry}
        material={nodes.Cube026.material}
        position={[-25.72, 0.02, -45.36]}
        rotation={[0, 0.85, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube027.geometry}
        material={nodes.Cube027.material}
        position={[-24.06, 0.33, -36.76]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube028.geometry}
        material={nodes.Cube028.material}
        position={[-8.5, 0.59, -38.86]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube029.geometry}
        material={nodes.Cube029.material}
        position={[-7.43, 0.59, -38.86]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube030.geometry}
        material={nodes.Cube030.material}
        position={[-6.36, 0.59, -38.86]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube031.geometry}
        material={nodes.Cube031.material}
        position={[4.61, 0.5, 14.49]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube032.geometry}
        material={nodes.Cube032.material}
        position={[5.46, 0.53, 8.91]}
        rotation={[-Math.PI, 0.75, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube033.geometry}
        material={nodes.Cube033.material}
        position={[6.28, 0.36, 8.06]}
        rotation={[-Math.PI, -0.81, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube034.geometry}
        material={nodes.Cube034.material}
        position={[7.81, 0.4, 11.57]}
        rotation={[-Math.PI, 0.91, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube035.geometry}
        material={nodes.Cube035.material}
        position={[11.92, 0.42, 11.43]}
        rotation={[0, 0.43, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube036.geometry}
        material={nodes.Cube036.material}
        position={[11.89, 0.29, 15.68]}
        rotation={[0, 0.14, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube037.geometry}
        material={nodes.Cube037.material}
        position={[5.53, 0.22, 14.45]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube038.geometry}
        material={nodes.Cube038.material}
        position={[-28.19, 0.39, -50.77]}
        rotation={[0, 0.91, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube039.geometry}
        material={nodes.Cube039.material}
        position={[-26.47, 0.25, -52.72]}
        rotation={[0, 0.91, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube040.geometry}
        material={nodes.Cube040.material}
        position={[-29.51, 0.6, -53.31]}
        rotation={[0, 0.91, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube041.geometry}
        material={nodes.Cube041.material}
        position={[-25.66, 0.25, -52.09]}
        rotation={[0, 0.91, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube042.geometry}
        material={nodes.Cube042.material}
        position={[-24.86, 0.25, -51.47]}
        rotation={[0, 0.91, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube043.geometry}
        material={nodes.Cube043.material}
        position={[-6.13, 0.38, 36.19]}
        rotation={[0.01, 0, 0.01]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube044.geometry}
        material={nodes.Cube044.material}
        position={[-2.71, 0.35, 37.44]}
        rotation={[0, 0.49, 0.01]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube045.geometry}
        material={nodes.Cube045.material}
        position={[-13.51, 0.42, 19.42]}
        rotation={[2.96, -1.53, 2.96]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube046.geometry}
        material={nodes.Cube046.material}
        position={[-16.69, 0.92, 19.72]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube047.geometry}
        material={nodes.Cube047.material}
        position={[-15.54, 0.67, 19.05]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube048.geometry}
        material={nodes.Cube048.material}
        position={[-12.01, 0.31, 18.26]}
        rotation={[0.01, -0.04, 0.01]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube051.geometry}
        material={nodes.Cube051.material}
        position={[-7.15, 0.97, 7.61]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube052.geometry}
        material={nodes.Cube052.material}
        position={[-4.81, 0.04, 5.15]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube055.geometry}
        material={nodes.Cube055.material}
        position={[-7.18, 0.91, -17.12]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube056.geometry}
        material={nodes.Cube056.material}
        position={[-7.19, 1, -15.27]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube057.geometry}
        material={nodes.Cube057.material}
        position={[-9.41, 1, -16.29]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube058.geometry}
        material={nodes.Cube058.material}
        position={[-10.49, 3.09, -12.97]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube059.geometry}
        material={nodes.Cube059.material}
        position={[-3.26, 0.58, -17.45]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube060.geometry}
        material={nodes.Cube060.material}
        position={[-3.26, 0.94, -18.87]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube061.geometry}
        material={nodes.Cube061.material}
        position={[-24.16, 0.91, 13.26]}
        rotation={[0, -1.57, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube062.geometry}
        material={nodes.Cube062.material}
        position={[-22.31, 0.91, 13.27]}
        rotation={[0, -1.57, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube063.geometry}
        material={nodes.Cube063.material}
        position={[-24.17, 0.89, 16.25]}
        rotation={[0, -1.57, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube064.geometry}
        material={nodes.Cube064.material}
        position={[-24.17, 0.89, 18.75]}
        rotation={[0, -1.57, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube065.geometry}
        material={nodes.Cube065.material}
        position={[-24.17, 0.96, 22.02]}
        rotation={[0, -1.57, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube066.geometry}
        material={nodes.Cube066.material}
        position={[-25.59, 1.57, 23.24]}
        rotation={[0, -1.57, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube067.geometry}
        material={nodes.Cube067.material}
        position={[-34.15, 0.92, 17.12]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube068.geometry}
        material={nodes.Cube068.material}
        position={[-33.48, 0.67, 18.27]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube069.geometry}
        material={nodes.Cube069.material}
        position={[-35.99, 0.38, 27]}
        rotation={[0.01, 0, 0.01]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube070.geometry}
        material={nodes.Cube070.material}
        position={[-16.88, 0.67, 12.38]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube074.geometry}
        material={nodes.Cube074.material}
        position={[15.89, 0.35, 13.54]}
        rotation={[0, -0.63, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube075.geometry}
        material={nodes.Cube075.material}
        position={[-4.72, 0.95, -4.28]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube076.geometry}
        material={nodes.Cube076.material}
        position={[-4.72, 0.9, -7.36]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube079.geometry}
        material={nodes.Cube079.material}
        position={[1.88, 0.43, -8.41]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane005.geometry}
        material={nodes.Plane005.material}
        position={[-20.1, -0.12, -14.3]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube001.geometry}
        material={nodes.Cube001.material}
        position={[-22.76, 0.11, -23.26]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube021.geometry}
        material={nodes.Cube021.material}
        position={[-21.8, 0.35, 6.9]}
        rotation={[Math.PI, -0.02, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube081.geometry}
        material={nodes.Cube081.material}
        position={[8.55, 0.5, -31.27]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube082.geometry}
        material={nodes.Cube082.material}
        position={[9.52, 1.01, -30.98]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube083.geometry}
        material={nodes.Cube083.material}
        position={[9.25, 0.27, -37.68]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube085.geometry}
        material={nodes.Cube085.material}
        position={[3.38, 0.95, -8.01]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube086.geometry}
        material={nodes.Cube086.material}
        position={[-4.91, 0.5, -30.01]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube088.geometry}
        material={nodes.Cube088.material}
        position={[-7.33, 0.9, -19.74]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube089.geometry}
        material={nodes.Cube089.material}
        position={[-9.18, 0.9, -19.75]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube090.geometry}
        material={nodes.Cube090.material}
        position={[-7.32, 0.9, -21.97]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube002.geometry}
        material={nodes.Cube002.material}
        position={[10.75, 0.38, -18.07]}
        rotation={[3.14, 0, -3.13]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube003.geometry}
        material={nodes.Cube003.material}
        position={[8.14, 0.38, -18.07]}
        rotation={[3.14, 0, -3.13]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube004.geometry}
        material={nodes.Cube004.material}
        position={[-7.42, 0.58, 11.93]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube005.geometry}
        material={nodes.Cube005.material}
        position={[-8.85, 0.95, 10.24]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube098.geometry}
        material={nodes.Cube098.material}
        position={[-16.88, 0.67, 10.6]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube099.geometry}
        material={nodes.Cube099.material}
        position={[-16.88, 0.83, 8.69]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube100.geometry}
        material={nodes.Cube100.material}
        position={[-12.19, 0.89, 12]}
        rotation={[0, -1.57, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube101.geometry}
        material={nodes.Cube101.material}
        position={[-11.97, 0.89, 9.32]}
        rotation={[0, -1.57, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube102.geometry}
        material={nodes.Cube102.material}
        position={[-14.67, 0.62, 12.3]}
        rotation={[0, -1.57, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube103.geometry}
        material={nodes.Cube103.material}
        position={[1.14, 1, -14.42]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube104.geometry}
        material={nodes.Cube104.material}
        position={[-11.07, 0.61, -26.59]}
        rotation={[0, -Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube105.geometry}
        material={nodes.Cube105.material}
        position={[-9.22, 0.88, -26.58]}
        rotation={[0, -Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube106.geometry}
        material={nodes.Cube106.material}
        position={[-11.08, 0.88, -24.46]}
        rotation={[0, -Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube107.geometry}
        material={nodes.Cube107.material}
        position={[-14.57, 1, -22.61]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube108.geometry}
        material={nodes.Cube108.material}
        position={[-12.12, 0.42, 15.65]}
        rotation={[0.18, 1.53, -0.18]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube109.geometry}
        material={nodes.Cube109.material}
        position={[-13.62, 0.31, 16.81]}
        rotation={[3.14, 0.04, -3.13]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube110.geometry}
        material={nodes.Cube110.material}
        position={[-3.3, 0.42, 34]}
        rotation={[0.01, -0.01, -0.01]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube087.geometry}
        material={nodes.Cube087.material}
        position={[-18.38, 0.89, -35.95]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube007.geometry}
        material={nodes.Cube007.material}
        position={[16.9, 0.39, -29.56]}
        rotation={[0, -Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube006.geometry}
        material={nodes.Cube006.material}
        position={[16.9, 0.39, -28.12]}
        rotation={[0, -Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube049.geometry}
        material={nodes.Cube049.material}
        position={[16.9, 0.39, -26.69]}
        rotation={[0, -Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube050.geometry}
        material={nodes.Cube050.material}
        position={[-1.7, 0.79, -9.52]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube071.geometry}
        material={nodes.Cube071.material}
        position={[-1.7, 0.79, -3.13]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube072.geometry}
        material={nodes.Cube072.material}
        position={[-1.7, 0.47, -6.28]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube077.geometry}
        material={nodes.Cube077.material}
        position={[-1.7, 0.79, -6.42]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube078.geometry}
        material={nodes.Cube078.material}
        position={[7.97, 0.39, -5.25]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube112.geometry}
        material={nodes.Cube112.material}
        position={[7.97, 0.39, -6.74]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube113.geometry}
        material={nodes.Cube113.material}
        position={[7.97, 0.39, -8.22]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube114.geometry}
        material={nodes.Cube114.material}
        position={[7.97, 0.39, -9.65]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube073.geometry}
        material={nodes.Cube073.material}
        position={[-4.72, 1.52, -9.46]}
      />
      <group position={[-24.11, 0, 8.45]} rotation={[Math.PI / 4, 0, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle004.geometry}
          material={nodes.Circle004.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle004_1.geometry}
          material={nodes.Circle004_1.material}
        />
      </group>
      <group position={[-24.15, 0, -3.71]} rotation={[2.36, 0, Math.PI]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle003.geometry}
          material={nodes.Circle003.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle003_1.geometry}
          material={nodes.Circle003_1.material}
        />
      </group>
      <mesh
        receiveShadow
        onClick={e => updateLastPositionClicked(e.point.x, e.point.z)}
        geometry={nodes.Plane.geometry}
        position={[-3.16, -0.01, -9.83]}
      >
        <meshPhongMaterial
          reflectivity={0}
          name="Ground"
          material={materials["Ground_ShadowBaked.001"]}
          color={props.groundColor}
        />
      </mesh>
    </group>
  )
}

useGLTF.preload("/assets/LOE_Environment_THREE_MODEL_COMBINED_SCALED.gltf")
