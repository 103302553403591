import { Link } from "../components/Link"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import TransitionLink from "./TansitionLink"
import { Button, Text, Center, Box } from "@chakra-ui/react"
import { moveCurrentPageOut } from "./Animation/PageTransition"
import { moveCameraToLayer } from "./Animation/CameraAnimation"
import MenuDrawerRefactored from "./Menu/MenuDrawerRefactored"
import { graphql, useStaticQuery } from "gatsby"

const Header = props => {
  const sendActionToStore = useDispatch()
  const CURRENT_LANG = useSelector(state => state.currentLang)
  const TRANSLATED_URL = useSelector(state => state.translatedUrl)
  const data = useStaticQuery(graphql`
    query {
      wpModeSettings(
        slug: { eq: "global" }
        locale: { locale: { eq: "de_DE" } }
      ) {
        acf_global_settings {
          textPagetitle
          englishAvailable
        }
      }
    }
  `)
  let currentPath = ""
  if (typeof window !== `undefined`) {
    currentPath = window.location.pathname
    var linkTo = "/" + CURRENT_LANG
    if (currentPath === linkTo) {
      linkTo = "/"
    }
  } else {
    linkTo = "/"
  }

  const [headerOnTop, setHeaderOnTop] = useState(false)

  function scrollFunction() {
    if (window.pageYOffset > 64 && !headerOnTop) {
      setHeaderOnTop(true)
    } else if (window.pageYOffset < 64) {
      setHeaderOnTop(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollFunction)
    return () => {
      window.removeEventListener("scroll", scrollFunction)
    }
  })

  const GROUND_COLOR = useSelector(state => state.map.groundColor)

  // * Singlepage Header Color
  // TODO: Wenn Singlepage Hintergrundfarbe dynamisch im CMS -> Hier einfügen
  const [bgColor, setBgColor] = useState(GROUND_COLOR)

  useEffect(() => {
    setBgColor(props.singlePage ? "#ebf8ff" : GROUND_COLOR)
  }, [GROUND_COLOR, props.singlePage])

  return (
    <Box
      className={
        "fixed w-full mx-auto flex flex-row justify-between pointer-events-none " +
        props.className
      }
      p={
        !headerOnTop
          ? { base: "16px 32px", sm: "48px" }
          : { base: "8px 32px", sm: "16px 32px", md: "16px 48px" }
      }
      background={!headerOnTop ? "transparent" : bgColor}
      shadow={!headerOnTop ? "none" : "lg"}
      transition="all 0.2s ease-out"
    >
      <TransitionLink
        className="pointer-events-auto cursor-pointer"
        to={linkTo}
        exit={{
          length: 1,
        }}
        entry={{
          delay: 0,
        }}
        state={{ direction: "fade" }}
        onClick={() => {
          moveCameraToLayer(sendActionToStore, "intro")
          moveCurrentPageOut(sendActionToStore, "fade")
          // sendActionToStore({
          //   type: "change/map/groundColor",
          //   payload: "#ffffff",
          // })
          sendActionToStore({
            type: "change/map/marker",
            payload: [],
          })
        }}
      >
        <Center h="100%" className="font-bold font-s4">
          <Box>{data.wpModeSettings.acf_global_settings?.textPagetitle}</Box>
        </Center>
      </TransitionLink>

      <div className="flex flex-row pointer-events-auto">
        {data.wpModeSettings.acf_global_settings?.englishAvailable && (
          <Text display={{ base: "none", md: "block" }} mr="2">
            <Link to={CURRENT_LANG !== "en" ? currentPath : TRANSLATED_URL}>
              <Button
                variant="unstyled"
                background="transparent"
                fontWeight={CURRENT_LANG !== "en" ? "bold" : "normal"}
                rounded="full"
                _hover={{ background: "buttonTertiary.500" }}
                p="0"
              >
                DE
              </Button>
            </Link>
            <Link to={CURRENT_LANG === "en" ? currentPath : TRANSLATED_URL}>
              <Button
                variant="unstyled"
                background="transparent"
                fontWeight={CURRENT_LANG === "en" ? "bold" : "normal"}
                rounded="full"
                _hover={{ background: "buttonTertiary.500" }}
                p="0"
              >
                EN
              </Button>
            </Link>
          </Text>
        )}
        <MenuDrawerRefactored className="z-20" />
        {/* <Hamburger onClick={mRef.onOpen} /> */}
      </div>
    </Box>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

Header.defaultProps = {
  className: ``,
}

export default Header
