import React, { useState, useEffect } from "react"
import MarkerScene from "./MarkerScene"

const Markers = props => {
  // let arr = props.data

  const [markersArray, setMarkersArray] = useState(props.data)

  useEffect(() => {
    setMarkersArray(props.data)
  }, [props.data])

  const AllMarkers = markersArray.map(
    (el, i) =>
      el !== false && (
        <MarkerScene
          key={el.name + i.toString()}
          id={i}
          isPreview={props.isPreview}
          psd={props.psd}
          // setMarkers={addMarkers}
          data={el}
          types={props.types}
          portal={props.portal}
        />
      )
  )

  return AllMarkers
}

export default Markers
