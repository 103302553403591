export const moveCameraToLayer = (sendActionToStore, layerString) => {
  let toCameraPosition = null

  // console.log("moveCameraToLayer: " + layerString)

  switch (layerString) {
    case "intro":
      toCameraPosition = {
        x: 20,
        y: 40,
        z: 20,
        zoom: 15,
        targetX: -5,
        targetY: 0,
        targetZ: -5,
      }
      break
    case "schwerpunkte":
      toCameraPosition = {
        x: 20,
        y: 40,
        z: 20,
        zoom: 45,
        targetX: -5,
        targetY: 0,
        targetZ: -5,
      }
      break
    case "schwerpunkte-1":
      toCameraPosition = {
        x: 20,
        y: 40,
        z: -20,
        zoom: 45,
        targetX: -5,
        targetY: 0,
        targetZ: -5,
      }
      break
    case "schwerpunkte-2":
      toCameraPosition = {
        x: -20,
        y: 40,
        z: -20,
        zoom: 45,
        targetX: -5,
        targetY: 0,
        targetZ: -5,
      }
      break
    case "schwerpunkte-3":
      toCameraPosition = {
        x: -20,
        y: 40,
        z: 20,
        zoom: 45,
        targetX: -5,
        targetY: 0,
        targetZ: -5,
      }
      break
    case "projektziele":
      toCameraPosition = {
        x: 20,
        y: 40,
        z: 20,
        zoom: 60,
        targetX: -5,
        targetY: 0,
        targetZ: -5,
      }
      break
    case "karte":
      toCameraPosition = {
        x: -8,
        y: 50,
        z: -6,
        zoom: 15,
        targetX: -5,
        targetY: -3,
        targetZ: -6,
      }
      break
    case "karteMobile":
      toCameraPosition = {
        x: 0,
        y: 50,
        z: 5,
        zoom: 7,
        targetX: 0,
        targetY: 20,
        targetZ: -5,
      }
      break
    default:
      toCameraPosition = {
        x: 20,
        y: 40,
        z: 20,
        zoom: 15,
        targetX: -5,
        targetY: 0,
        targetZ: -5,
      }
      break
  }
  sendActionToStore({
    type: `animateCameraToPosition`,
    payload: toCameraPosition,
  })
}

export const moveCameraToPosition = (
  sendActionToStore,
  x,
  z,
  y = "default",
  zoom = "default"
) => {
  let toCameraPosition = null

  const distanceToCenter = {
    x: 25,
    y: 35,
    z: 25,
  }

  if (y === "default") {
    y = 0
  }

  if (zoom === "default") {
    zoom = 60
  }

  toCameraPosition = {
    x: x + distanceToCenter.x,
    y: y + distanceToCenter.y,
    z: z + distanceToCenter.z,
    zoom: zoom,
    targetX: x,
    targetY: y,
    targetZ: z,
  }

  sendActionToStore({
    type: `animateCameraToPosition`,
    payload: toCameraPosition,
  })
}
