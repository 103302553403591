import * as React from "react"
import { useState } from "react"
import { Link } from "../Link"
import { useStaticQuery, graphql } from "gatsby"
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  Spacer,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  Center,
  Flex,
} from "@chakra-ui/react"
import Navigation from "./Navigation"
import Lexikon from "./Lexikon"
import Search from "./Search"
import Hamburger from "./Hamburger"
import IconClose from "../Icons/IconClose"
import IconSearch from "../Icons/IconSearch"
import { getURL, setURL } from "../../tools/helper"
import { useDispatch, useSelector } from "react-redux"

const MenuDrawerRefactored = props => {
  const sendActionToStore = useDispatch()
  const CURRENT_LANG = useSelector(state => state.currentLang)
  const TRANSLATED_URL = useSelector(state => state.translatedUrl)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [tabOpened, setTabOpened] = useState(0)
  function searchOpen() {
    setTabOpened(1)
    setURL([["menu", 1]])
    onOpen()
  }

  const data = useStaticQuery(graphql`
    query MenuQuery {
      wpModeSettings(slug: { eq: "global" }, locale: { id: { eq: "de_DE" } }) {
        acf_global_settings {
          englishAvailable
        }
      }
    }
  `)

  let currentPath = ""
  if (typeof window !== `undefined`) {
    currentPath = window.location.pathname
  }
  const settings = data.wpModeSettings

  function menuOpen() {
    setTabOpened(0)
    setURL([["menu", 0]])
    onOpen()
    sendActionToStore({
      type: `menu/openMenu`,
      payload: {
        menuIsOpen: true,
        selectedTab: 0,
      },
    })
  }

  React.useEffect(() => {
    const currentMenu = getURL("menu")
    if (getURL("menu")) {
      setTabOpened(parseInt(currentMenu))
      // console.log("currentMenu2", currentMenu, tabOpened)
      onOpen()
    }
  }, [])

  function openLexicon() {
    setTabOpened(2)
    // console.log("Open Lexicon")
    // console.log("Tab:", tabOpened)
  }

  return (
    <>
      <Button
        variant="unstyled"
        background="transparent"
        rounded="full"
        _hover={{ background: "buttonTertiary.500" }}
        aria-label="Suchen"
        onClick={searchOpen}
        w="24px"
        display={{ base: "none", md: "inherit" }}
      >
        <Center>
          <IconSearch className="w-5" />
        </Center>
      </Button>
      <Button
        variant="unstyled"
        background="transparent"
        rounded="full"
        _hover={{ background: "buttonTertiary.500" }}
        aria-label={CURRENT_LANG === "en" ? "Open navigation" : "Menü öffnen"}
        onClick={menuOpen}
        w="24px"
      >
        <Center>
          <Hamburger zIndex="50" />
        </Center>
      </Button>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody p={{ base: "32px 16px", md: "28px 48px" }}>
            <Flex
              w="100%"
              display={{
                base: settings?.acf_global_settings?.englishAvailable
                  ? "flex"
                  : "none",
                md: "none",
              }}
            >
              {settings?.acf_global_settings?.englishAvailable && (
                <>
                  <Text w="fit-content" m="0px auto">
                    {CURRENT_LANG !== "de" ? (
                      <Link
                        to={
                          CURRENT_LANG !== "en" ? currentPath : TRANSLATED_URL
                        }
                        className="outline-none"
                      >
                        <Button
                          variant="unstyled"
                          background="transparent"
                          fontWeight={CURRENT_LANG !== "en" ? "bold" : "normal"}
                          rounded="full"
                          _hover={{ background: "buttonTertiary.500" }}
                          p="0"
                        >
                          DE
                        </Button>
                      </Link>
                    ) : (
                      <Button
                        variant="unstyled"
                        background="transparent"
                        fontWeight={CURRENT_LANG !== "en" ? "bold" : "normal"}
                        rounded="full"
                        _hover={{ background: "buttonTertiary.500" }}
                        p="0"
                      >
                        DE
                      </Button>
                    )}
                  </Text>
                  <Text w="fit-content" m="0px auto">
                    <Link
                      to={CURRENT_LANG === "en" ? currentPath : TRANSLATED_URL}
                      className="outline-none"
                    >
                      <Button
                        variant="unstyled"
                        background="transparent"
                        fontWeight={CURRENT_LANG === "en" ? "bold" : "normal"}
                        rounded="full"
                        _hover={{ background: "buttonTertiary.500" }}
                        p="0"
                      >
                        EN
                      </Button>
                    </Link>
                  </Text>
                </>
              )}
              <Spacer />
              <IconClose
                responsive
                onClick={() => {
                  setURL([["", 0]], true)
                  onClose()
                }}
                className="w-4 h-4"
              />
            </Flex>
            <Tabs
              defaultIndex={tabOpened}
              index={tabOpened}
              size="md"
              mt="4"
              variant="soft-rounded"
              colorScheme="buttonTertiary"
              onChange={index => {
                setURL([["menu", index]])
              }}
            >
              <Flex
                direction={["column-reverse", "row", "row", "row"]}
                alignItems={[
                  "flex-end",
                  "flex-start",
                  "flex-start",
                  "flex-start",
                ]}
              >
                <TabList w="100%">
                  <Tab
                    _selected={{
                      transform: "scale(1.05)",
                      background: "buttonTertiary.100",
                    }}
                    _hover={{
                      background: "buttonTertiary.100",
                    }}
                    mr="2"
                    onClick={() => setTabOpened(0)}
                  >
                    {CURRENT_LANG === "en" ? "Navigation" : "Navigation"}
                  </Tab>
                  <Tab
                    _selected={{
                      transform: "scale(1.05)",
                      background: "buttonTertiary.100",
                    }}
                    _hover={{
                      background: "buttonTertiary.100",
                    }}
                    mr="2"
                    onClick={() => setTabOpened(1)}
                  >
                    {CURRENT_LANG === "en" ? "Search" : "Suche"}
                  </Tab>
                  <Tab
                    _selected={{
                      transform: "scale(1.05)",
                      background: "buttonTertiary.100",
                    }}
                    _hover={{
                      background: "buttonTertiary.100",
                    }}
                    onClick={() => setTabOpened(2)}
                  >
                    {CURRENT_LANG === "en" ? "Vocabulary" : "Vokabular"}
                  </Tab>
                </TabList>
                <IconClose
                  isLanguage={settings?.acf_global_settings?.englishAvailable}
                  onClick={() => {
                    setURL([["", 0]], true)
                    onClose()
                  }}
                  className="w-4"
                />
              </Flex>
              <TabPanels>
                <TabPanel p={0}>
                  <Navigation closeMenu={onClose} />
                </TabPanel>
                <TabPanel p={0}>
                  <Search closeMenu={onClose} openLexicon={openLexicon} />
                </TabPanel>
                <TabPanel p={0}>
                  <Lexikon tabOpened={tabOpened} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default MenuDrawerRefactored
