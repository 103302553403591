import { Box } from "@chakra-ui/react"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import Dot from "./Dot"

const SidebarIndicator = props => {
  const sendActionToStore = useDispatch()
  const CURRENT_LOCALE = useSelector(state => state.currentLocaleID)

  const data = useStaticQuery(graphql`
    query SidebarQuery {
      de_DE: wpModeSettings(
        slug: { eq: "global" }
        locale: { id: { eq: "de_DE" } }
      ) {
        acf_global_settings {
          zukunftsbildSlug
          sidebarIndicators {
            link
          }
        }
      }
      en_US: wpModeSettings(
        slug: { eq: "global" }
        locale: { id: { eq: "en_US" } }
      ) {
        acf_global_settings {
          sidebarIndicators {
            link
          }
        }
      }
    }
  `)

  let sidebarIndicators =
    data[CURRENT_LOCALE].acf_global_settings.sidebarIndicators
  const queryUrls = useCallback(() => {
    let arr = []
    // console.log(sidebarIndicators)
    if (sidebarIndicators.length > 0)
      sidebarIndicators.map(i => arr.push(i.link))
    return arr
  }, [sidebarIndicators])

  const dotPages = queryUrls()
  // const dotPages = ["/", "/schwerpunkte/", "/projektziele/"]
  // console.log(dotPages)

  const CURRENT_LANG = useSelector(state => state.currentLang)

  return (
    <Box
      className={
        "fixed flex flex-col justify-center items-center h-full " +
        props.className
      }
      pl={["38px", "38px", "38px", "38px", "38px", "48px"]}
      alignItems="flex-start"
    >
      <Dot
        key={"uebersicht"}
        index={-1}
        link={CURRENT_LANG === "en" ? "/en/overview" : "/uebersicht"}
        allPages={dotPages}
        setExit={props.setExit}
        setCurrentDirection={props.setCurrentDirection}
        lang={CURRENT_LOCALE}
        homeSlug={data[CURRENT_LOCALE].acf_global_settings.zukunftsbildSlug}
      />
      {dotPages.map((dotPageLink, index) => {
        return (
          <Dot
            key={index}
            index={index}
            link={dotPageLink}
            allPages={dotPages}
            setExit={props.setExit}
            setCurrentDirection={props.setCurrentDirection}
            lang={CURRENT_LOCALE}
            homeSlug={data[CURRENT_LOCALE].acf_global_settings.zukunftsbildSlug}
          />
        )
      })}
    </Box>
  )
}

SidebarIndicator.propTypes = {
  className: PropTypes.string,
}

SidebarIndicator.defaultProps = {
  className: "",
}

export default SidebarIndicator
