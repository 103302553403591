// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-karte-js": () => import("./../../../src/pages/karte.js" /* webpackChunkName: "component---src-pages-karte-js" */),
  "component---src-pages-projectscene-designer-js": () => import("./../../../src/pages/projectsceneDesigner.js" /* webpackChunkName: "component---src-pages-projectscene-designer-js" */),
  "component---src-templates-content-forschung-js": () => import("./../../../src/templates/content/forschung.js" /* webpackChunkName: "component---src-templates-content-forschung-js" */),
  "component---src-templates-content-forschungsschwerpunkt-js": () => import("./../../../src/templates/content/forschungsschwerpunkt.js" /* webpackChunkName: "component---src-templates-content-forschungsschwerpunkt-js" */),
  "component---src-templates-content-projektziel-js": () => import("./../../../src/templates/content/projektziel.js" /* webpackChunkName: "component---src-templates-content-projektziel-js" */),
  "component---src-templates-content-schwerpunkt-js": () => import("./../../../src/templates/content/schwerpunkt.js" /* webpackChunkName: "component---src-templates-content-schwerpunkt-js" */),
  "component---src-templates-content-single-page-js": () => import("./../../../src/templates/content/SinglePage.js" /* webpackChunkName: "component---src-templates-content-single-page-js" */),
  "component---src-templates-content-zukunftsbild-js": () => import("./../../../src/templates/content/zukunftsbild.js" /* webpackChunkName: "component---src-templates-content-zukunftsbild-js" */),
  "component---src-templates-intro-forschung-js": () => import("./../../../src/templates/intro/forschung.js" /* webpackChunkName: "component---src-templates-intro-forschung-js" */),
  "component---src-templates-intro-karte-js": () => import("./../../../src/templates/intro/karte.js" /* webpackChunkName: "component---src-templates-intro-karte-js" */),
  "component---src-templates-intro-projektziele-js": () => import("./../../../src/templates/intro/projektziele.js" /* webpackChunkName: "component---src-templates-intro-projektziele-js" */),
  "component---src-templates-intro-schwerpunkte-js": () => import("./../../../src/templates/intro/schwerpunkte.js" /* webpackChunkName: "component---src-templates-intro-schwerpunkte-js" */),
  "component---src-templates-intro-zukunftsbild-js": () => import("./../../../src/templates/intro/zukunftsbild.js" /* webpackChunkName: "component---src-templates-intro-zukunftsbild-js" */)
}

