import React, { useRef } from "react"
import { useFrame, extend, useThree } from "@react-three/fiber"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { useSpring } from "react-spring"
import { useSelector, useDispatch } from "react-redux"

// create orbitControls Component
extend({ OrbitControls })

const Camera = () => {
  // console.log("🎥  Camera Rerendered")
  // 🗄 Get Values from Store
  const CAMERA_VALUES = useSelector(state => state.camera)
  const ANIMATION_VALUES = useSelector(state => state.animation)
  const sendActionToStore = useDispatch()

  // 🎥 Setup Camera
  const controlsInfo = useRef()
  const { camera, gl } = useThree()

  // setup for orthographic
  camera.near = 0.001
  camera.far = 1000

  // 🌸 Create Spring Animation for all Camera Changes
  const [animatedCameraChords, setAnimatedCameraChords] = useSpring(() => ({
    chords: [
      CAMERA_VALUES.position.x,
      CAMERA_VALUES.position.y,
      CAMERA_VALUES.position.z,
      CAMERA_VALUES.zoom,
      CAMERA_VALUES.position.targetX,
      CAMERA_VALUES.position.targetY,
      CAMERA_VALUES.position.targetZ,
    ],
    // config: { mass: 160, tension: 54, friction: 1, clamp: true },
    config: {
      mass: 1,
      tension: 40,
      friction: 60,
      clamp: true,
      precision: 0.001,
    },
    onStart: () => {
      // console.log("🎥 Animation Started!")
      sendActionToStore({
        type: "animation/isCameraAnimationRunning",
        payload: true,
      })
    },
    onRest: () => {
      // console.log("🎥 Animation Ended!")
      sendActionToStore({
        type: "animation/isCameraAnimationRunning",
        payload: false,
      })
    },
  }))

  // Loop - Every Frame
  useFrame(() => {
    if (controlsInfo.current) {
      // 🖱 Mouse Control
      controlsInfo.current.enabled = CAMERA_VALUES.mouseControlEnabled

      // 🔄 Get Current Camera Position
      if (ANIMATION_VALUES.triggerUpdateCameraPosition) {
        // Update Camera Position to Store
        sendActionToStore({
          type: "update/cameraPosition",
          payload: {
            x: controlsInfo.current.object.position.x,
            y: controlsInfo.current.object.position.y,
            z: controlsInfo.current.object.position.z,
            zoom: controlsInfo.current.object.zoom,
            targetX: controlsInfo.current.target.x,
            targetY: controlsInfo.current.target.y,
            targetZ: controlsInfo.current.target.z,
          },
        })

        // Turn Update Trigger Off
        sendActionToStore({
          type: `animation/triggerUpdateCameraPosition`,
          payload: false,
        })
      }

      //🚀 Animate to Position
      setAnimatedCameraChords.start({
        chords: [
          ANIMATION_VALUES.toPosition.x,
          ANIMATION_VALUES.toPosition.y,
          ANIMATION_VALUES.toPosition.z,
          ANIMATION_VALUES.toPosition.zoom,
          ANIMATION_VALUES.toPosition.targetX,
          ANIMATION_VALUES.toPosition.targetY,
          ANIMATION_VALUES.toPosition.targetZ,
        ],
      })
      // Only if mouseControl is disabled
      if (!controlsInfo.current.enabled) {
        // Set Chords to calculated Spring Values
        let springCameraChords = animatedCameraChords.chords.get()

        // Update Zoom
        camera.zoom = springCameraChords[3]
        camera.updateProjectionMatrix()

        // Update Position
        controlsInfo.current.object.position.set(
          springCameraChords[0],
          springCameraChords[1],
          springCameraChords[2]
        )
        controlsInfo.current.target.set(
          springCameraChords[4],
          springCameraChords[5],
          springCameraChords[6]
          // springCameraChords[7]
        )
      }
      controlsInfo.current.update()
    }
  })

  return (
    <>
      <orbitControls
        ref={controlsInfo}
        args={[camera, gl.domElement]}
        minDistance="1"
        maxDistance="200"
      />
    </>
  )
}

export default Camera
