import React, { useState, useRef, useEffect } from "react"
import { Html } from "@react-three/drei"
import * as THREE from "three"
import { useSelector, useDispatch } from "react-redux"
import { moveCameraToPosition } from "../../components/Animation/CameraAnimation"
import IconFlag from "../Icons/IconFlag"
import { useOutsideClick } from "@chakra-ui/react"

// * creates vector-representations in the 3D scene

const MarkerScene = props => {
  const { x, y } = props.data

  const sendActionToStore = useDispatch()

  // * Refs
  const m = useRef()
  const longText = useRef()
  const htmlRef = useRef()
  const pageOneRef = useRef()

  // * States
  const [modalOpen, setModalOpen] = useState(false)
  const [pageActive, setPageActive] = useState(false)
  const [pageHeight, setPageHeight] = useState(300)
  const [fade, setFade] = useState(false)

  const MAP_VALUES = useSelector(state => state.map)

  // * Close on outside click
  useOutsideClick({
    ref: htmlRef,
    handler: () => setModalOpen(false),
  })

  // * Get location of project
  const LOCATION = useSelector(state => state.activeLocation)

  useEffect(() => {
    if (longText.current !== undefined && longText.current !== null) {
      setPageHeight(longText.current.offsetHeight)
    }
  }, [])

  useEffect(() => {
    if (!props.psd) {
      if (modalOpen) {
        moveCameraToPosition(sendActionToStore, x - 1.5, y - 1.5, "default", 90)
      } else {
        moveCameraToPosition(
          sendActionToStore,
          LOCATION.x,
          LOCATION.y,
          "default",
          60
        )
      }
    }
  }, [modalOpen, x, y, sendActionToStore, LOCATION, props.psd])

  useEffect(() => {
    if (modalOpen) {
      setFade(true)
    } else {
      setFade(false)
    }
  }, [modalOpen])

  useEffect(() => {
    if (!modalOpen) {
      setPageActive(false)
    }
  }, [modalOpen])

  const isMobile = true

  let calculatedBottomMargin = -56 - pageOneRef.current?.offsetHeight

  // * Styling
  const buttonStyle = {
    width: "64px",
    height: "64px",
    borderRadius: "64px",
    marginLeft: "-32px",
    marginTop: "-32px",
    transition: "all 0.2s ease-out",
    // border: modalOpen && "2px solid #4A5568",
    boxShadow: modalOpen
      ? "0px 4px 5px rgba(0, 0, 0, .1)"
      : "0px 4px 5px rgba(0, 0, 0, .2)",
    transform: modalOpen ? "scale(1.1)" : "scale(1)",
  }

  const buttonStyleStatic = {
    width: "64px",
    height: "64px",
    background: MAP_VALUES.groundColor,
    borderRadius: "64px",
    marginLeft: "-32px",
    marginTop: "-32px",
  }

  const previewStyle = {
    width: "64px",
    height: "64px",
    background: "white",
    border: "8px solid green",
    borderRadius: "64px",
    marginLeft: "-32px",
    marginTop: "-32px",
    boxShadow: "0px 4px 5px rgba(0, 0, 0, .2)",
  }

  const modalStyle = {
    width: !pageActive ? "300px" : "360px",
    borderRadius: "12px",
    background: "white",
    marginLeft: isMobile ? "0" : "40px",
    left: !pageActive ? "-150px" : "-180px",
    overflow: "hidden",
    fontWeight: "bold",
    transform: fade ? "scale(1)" : "scale(0.8)",
    opacity: fade ? "1" : "0",
    pointerEvents: fade ? "auto" : "none",
    height: !pageActive ? "" : pageHeight,
    fontSize: "1em",
    top: isMobile ? `${calculatedBottomMargin}px` : "-16px",
    position: "absolute",
    boxShadow: "0px 4px 5px rgba(0, 0, 0, .2)",
    transition: "all 0.3s",
  }

  const titleStyle = {
    fontSize: "1.3rem",
    padding: "8px 16px",
    display: "block",
  }

  const expandedTitleStyle = {
    fontSize: "1.3rem",
    lineHeight: "1.3em",
    padding: "16px 24px",
    paddingBottom: "12px",
    display: "block",
  }

  const imageStyle = {
    minHeight: "200px",
    background: props.data.image !== "" ? `url(${props.data.image})` : "gray",
    backgroundSize: "cover",
    backgroundPosition: "center",
  }

  const linkStyle = {
    fontSize: "1.3rem",
    textAlign: "right",
    width: "100%",
    display: "block",
    padding: "8px 16px",
    lineHeight: "1.2em",
  }

  const pageOne = {
    transform: !pageActive ? "" : "translateX(-300px)",
    transition: "transform 0.2s",
  }

  const pageTwo = {
    maxHeight: "300px",
    overflowY: "scroll",
    paddingBottom: "8px",
    width: "360px",
    position: "absolute",
    top: "0",
    background: "white",
    transition: "transform 0.2s",
    transform: !pageActive ? "translateX(300px)" : "translateX(0px)",
    left: !pageActive ? "unset" : "300px",
  }

  const expandedContentStyle = {
    padding: "16px 24px",
    paddingTop: "0",
    paddingRight: "30px",
    fontWeight: "normal",
    fontSize: ".95em",
    lineHeight: "160%",
  }

  const buttonSlider = {
    width: "48px",
    height: "48px",
    borderRadius: "32px",
    color: "white",
    position: "absolute",
    opacity: fade ? "1" : "0",
    transform: fade ? "scale(1)" : "scale(0.7)",
    transition: "all .3s",
    display: props.data.canExpand && modalOpen ? "inherit" : "none",
    left: pageActive ? "156px" : "125px",
    top: isMobile ? "-215px" : "64px",
    zIndex: "100",
    boxShadow: "0px 4px 5px rgba(0, 0, 0, .2)",
  }

  // * Calculate Position
  const v1 = new THREE.Vector3()
  const overrideCalculatePosition = (el, camera, size) => {
    const objectPos = v1.setFromMatrixPosition(el.matrixWorld)
    objectPos.project(camera)
    const widthHalf = size.width / 2
    const heightHalf = size.height / 2
    return [
      Math.min(
        size.width - 44,
        Math.max(0, objectPos.x * widthHalf + widthHalf)
      ),
      Math.min(
        size.height - 50,
        Math.max(0, -(objectPos.y * heightHalf) + heightHalf)
      ),
    ]
  }

  const [isVisible, setIsVisible] = useState(true)

  let projectScene = useSelector(state => state.projectScene)

  useEffect(() => {
    if (projectScene.topVisible && props.data?.visibility === "top") {
      setIsVisible(true)
    } else if (
      projectScene.bottomVisible &&
      props.data?.visibility === "bottom"
    ) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }, [projectScene, props.data?.visibility])

  const styleVisible = {
    opacity: "1",
    transform: "scale(1)",
    transition: "all 0.3s cubic-bezier(.5,-0.23,.5,1.23)",
  }

  const styleInvisible = {
    opacity: "0",
    transform: "scale(.8)",
    transition: "all 0.3s cubic-bezier(.5,-0.23,.5,1.23)",
  }

  // * Nur native html nutzen -> performance
  return (
    <group position={[x, 0, y]}>
      <Html
        style={isVisible ? styleVisible : styleInvisible}
        portal={props.psd ? undefined : props.portal}
        zIndexRange={modalOpen ? [12, 11] : [10, 0]}
        calculatePosition={overrideCalculatePosition}
        ref={htmlRef}
      >
        {props.data.type === "static" && !props.isPreview ? (
          props.data.icon === "flag" ? (
            <div style={buttonStyleStatic}>
              <div style={{ padding: "16px" }}>
                <IconFlag />
              </div>
            </div>
          ) : (
            <div style={buttonStyleStatic}>
              <img alt="" src={props.data.icon} />
            </div>
          )
        ) : (
          <>
            <button
              style={props.isPreview ? previewStyle : buttonStyle}
              onClick={
                !props.isPreview ? () => setModalOpen(!modalOpen) : () => {}
              }
              className={
                modalOpen ? "interactiveMarker modalOpen" : "interactiveMarker"
              }
              id={
                "marker_" +
                props.data?.type +
                "_" +
                props.data?.title?.substring(0, 3)
              }
            >
              {props.isPreview ? "🚩" : <img alt="" src={props.data.icon} />}
            </button>
            <button
              style={buttonSlider}
              className="markerButtonSlider"
              onClick={() => setPageActive(!pageActive)}
            >
              {pageActive ? "←" : "→"}
            </button>
            <div style={modalStyle}>
              <div ref={pageOneRef} style={pageOne}>
                <span style={titleStyle}>{props.data.title}</span>
                <div style={imageStyle} />
                {props.data.link.url !== "" &&
                props.data.link.url !== null &&
                props.data.link.url !== undefined ? (
                  <a
                    className="linkSpanArrow"
                    href={props.data.link.url}
                    style={linkStyle}
                  >
                    {props.data.link.title && `${props.data.link.title}`}
                    <span>→</span>
                  </a>
                ) : (
                  <span style={linkStyle}>
                    {props.data.link.title && `${props.data.link.title}`}
                  </span>
                )}
                <div ref={longText} className="overflowScroll" style={pageTwo}>
                  <span style={expandedTitleStyle}>
                    {props.data.expandedContent?.title}
                  </span>
                  <p style={expandedContentStyle}>
                    {props.data.expandedContent?.content}
                  </p>
                  <a
                    className="linkSpanArrow"
                    href={props.data.expandedContent?.link?.url}
                    style={linkStyle}
                  >
                    {props.data.expandedContent?.link?.title &&
                      `${props.data.expandedContent?.link?.title}`}
                    <span>→</span>
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
      </Html>
      <mesh
        name="MarkerScene"
        ref={m}
        scale={[0.5, 0.5, 0.5]}
        position={[0, 0, 0]}
      />
    </group>
  )
}

export default MarkerScene
