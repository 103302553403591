import "./src/styles/global.css"
import wrapWithProvider from "./wrap-with-provider"
import "./src/components/Slider/Schwerpunkteslider.css"
export const wrapRootElement = wrapWithProvider

export const onInitialClientRender = () => {
  const isIeBrowser = !!window.MSCompatibleInfo
  if (isIeBrowser) {
    window.location.replace("/ie11.html")
  }
}
