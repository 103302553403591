import React, { useState, useRef, useEffect } from "react"
import { useSelector } from "react-redux"
import { Html } from "@react-three/drei"
import * as THREE from "three"
import { useOutsideClick } from "@chakra-ui/react"
import IconChevronDown from "../Icons/IconChevronDown"
// Creates representations in the 3D scene
// creates UI elements with their respective position
const LocationMarkers = props => {
  const CURRENT_LANG = useSelector(state => state.currentLang)
  const CURRENT_LOCALE = useSelector(state => state.currentLocaleID)

  function fixUrl(slug, locale, schwerpunkt) {
    if (locale === "en") {
      return `/en/mobility-focus/${schwerpunkt}/projectgoals/${slug}`
    }
    return `/mobilitaetsschwerpunkte/${schwerpunkt}/projektziele/${slug}`
  }

  // console.log(props.projects)
  function mapProjectsToScenes(sceneID) {
    const projects = []
    props.projects.nodes.forEach(project => {
      if (project.acf_scene.location !== null) {
        if (project.acf_scene.location.id === sceneID) {
          // console.log("project", project)
          // * Get full url for dropdown
          let fixedUrl = fixUrl(
            project.slug,
            CURRENT_LANG,
            project.acf_projektziel_content.groupLeft
              .relationMobilitaetsschwerpunkt.slug
          )
          // * Check for language
          if (
            (project.locale.locale === "en_US" && CURRENT_LANG === "en") ||
            (project.locale.locale === "de_DE" && CURRENT_LANG !== "en")
          ) {
            projects.push({
              name: project.acf_projektziel_content.groupLeft.textProjektname,
              url: fixedUrl,
            })
          }
        }
      }
    })
    return projects
  }

  return (
    <>
      {props.locations.nodes.length > 0 &&
        props.locations.nodes.map((sceneLocation, index) => {
          return (
            <LocationMarker
              key={sceneLocation.id + "_marker"}
              name={
                CURRENT_LOCALE === "de_DE"
                  ? sceneLocation.name
                  : sceneLocation.tax_translation.enTranslation
              }
              position={sceneLocation.acf_location.location}
              projects={mapProjectsToScenes(sceneLocation.id)}
              id={index}
              portal={props.portal}
              fadeOut={!props.locationsActive}
            />
          )
        })}
    </>
  )
}

const LocationMarker = props => {
  const { x, y } = props.position
  const m = useRef()

  const [isOpen, setIsOpen] = useState(false)
  const [fadeIn, setFadeIn] = useState(false)

  const currentActiveLocation = useSelector(state => state.activeLocation)
  const mapBackgroundColor = useSelector(state => state.map.groundColor)

  const [isActive, setIsActive] = useState(false)

  const htmlRef = useRef()

  useOutsideClick({
    ref: htmlRef,
    handler: () => setIsOpen(false),
  })

  useEffect(() => {
    if (
      currentActiveLocation.x === props.position.x &&
      currentActiveLocation.y === props.position.y
    ) {
      setIsActive(true)
    }
  }, [currentActiveLocation, props.position])

  useEffect(() => {
    if (props.fadeOut) {
      setFadeIn(false)
    }
  }, [props.fadeOut])

  // * Fade In effect after some time
  useEffect(() => {
    if (!fadeIn) {
      setTimeout(() => {
        setFadeIn(true)
      }, 2000 + 200 * props.id)
    }
  })

  const v1 = new THREE.Vector3()
  const overrideCalculatePosition = (el, camera, size) => {
    const objectPos = v1.setFromMatrixPosition(el.matrixWorld)
    objectPos.project(camera)
    const widthHalf = size.width / 2
    const heightHalf = size.height / 2
    return [
      Math.min(
        size.width - 44,
        Math.max(0, objectPos.x * widthHalf + widthHalf)
      ),
      Math.min(
        size.height - 50,
        Math.max(0, -(objectPos.y * heightHalf) + heightHalf)
      ),
    ]
  }

  const markerStyle = {
    display: "flex",
    padding: "10px 14px",
    borderRadius: "32px",
    fontSize: "1em",
    fontWeight: "bold",
    transition: "all 450ms ease-out",
    opacity: fadeIn ? "1" : "0",
    zIndex: "90",
    boxShadow: isActive
      ? `0 0 0px 6px ${mapBackgroundColor}`
      : "0px 4px 10px rgba(0,0,0,0.2)",
    transform: fadeIn ? "scale(1)" : "scale(0.8)",
    width: "max-content",
  }

  const listItemStyle = {
    display: "block",
    fontSize: ".9em",
    width: "100%",
    margin: "6px 6px",
    lineHeight: "1.2em",
  }

  const listContainerStyle = {
    listStyle: "none",
    width: "max-content",
    maxWidth: "400px",
    minWidth: "100%",
    background: "white",
    borderRadius: "16px",
    marginTop: "8px",
    zIndex: "100",
    padding: "8px",
    transition: "all 250ms ease-out",
    transform: isOpen ? "translateY(0px)" : "translateY(-8px)",
    opacity: isOpen ? "1" : "0",
    pointerEvents: isOpen ? "auto" : "none",
    boxShadow: "0px 4px 10px rgba(0,0,0,.2)",
  }

  // console.log("portal prop", props.portal)

  // * Nur native html nutzen -> performance
  return (
    <group position={[x, 0, y]}>
      <Html
        portal={props.portal}
        zIndexRange={!isOpen ? [10, 0] : [12, 11]}
        calculatePosition={overrideCalculatePosition}
        ref={htmlRef}
      >
        {props.projects.length > 0 ? (
          <button
            className={
              isOpen ? "interactiveMarker modalOpen" : "interactiveMarker"
            }
            onClick={() => setIsOpen(!isOpen)}
            style={markerStyle}
          >
            {props.name}
            <span
              style={{
                marginLeft: "4px",
                transition: "",
                width: "24px",
              }}
              className={isOpen && "flipX chevronAlign"}
            >
              <IconChevronDown />
            </span>
          </button>
        ) : (
          <div className="disabledMarker" style={markerStyle}>
            {props.name}
          </div>
        )}
        <div style={listContainerStyle}>
          {props.projects.length > 0 ? (
            props.projects.map(project => {
              return (
                <a
                  className="locationMarkerListItem"
                  style={listItemStyle}
                  href={project.url}
                >
                  <li className="linkSpanArrow" key={project.name}>
                    →<span className="multiLineLink">{project.name}</span>
                  </li>
                </a>
              )
            })
          ) : (
            <></>
          )}
        </div>
      </Html>
      <mesh
        name="LocationMarker"
        ref={m}
        scale={[0.5, 0.5, 0.5]}
        position={[0, 0, 0]}
      >
        {/* <cylinderGeometry args={[1, 1, 0.2, 16]} />
        <meshBasicMaterial color="hotpink" /> */}
      </mesh>
    </group>
  )
}

export default LocationMarkers
