import React from "react"
import { Link as GatsbyLink, navigate } from "gatsby"

export const Link = props => {
  /** Check if External Link and then take Path instead */
  let external = false
  const regex = RegExp(
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/
  )
  let url = props.to
  if (url !== undefined) {
    let res = regex.exec(url)
    if (res !== null && res[1] !== undefined) {
      url = res[1]
      external = true
    } else if (url === "" || url === undefined || url === null) {
      external = true
    }
  }
  return (
    <>
      {external ? (
        <a
          className={props.className}
          href={url}
          onMouseDown={
            props.onMouseDownClick
              ? () => {
                  if (typeof window !== `undefined`) {
                    window.open(url)
                  }
                }
              : undefined
          }
        >
          {props.children}
        </a>
      ) : (
        <GatsbyLink
          {...props}
          className={props.className}
          onMouseDown={
            props.onMouseDownClick
              ? () => {
                  navigate(props.to)
                }
              : undefined
          }
        >
          {props.children}
        </GatsbyLink>
      )}
    </>
  )
}
